/* eslint-disable sort-imports */

// Set global.applicationHistory so that on-location-changed will listen on it
import history from "shared/history.js"
import {callbacksHandler} from "on-location-changed/src/callbacks-handler"

callbacksHandler.connectReactRouterHistory(history)

// Use our own logging tool to log errors on Peakflow
import "shared/error-logger"

// Stimulus
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)

application.load(definitionsFromContext(context))


import {default as ApiMakerConfig} from "@kaspernj/api-maker/src/config"
ApiMakerConfig.setHistory(history)


// React layout and application
import "shared/shape-settings"
import "shared/source-maps"
import "shared/tests"
import "components/application"
import "stylesheets/application"
