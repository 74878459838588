import {createRoot} from "react-dom/client"
import ModelsResponseReader from "@kaspernj/api-maker/src/models-response-reader"
import {Container as Notifications} from "flash-notifications"
import Router from "@kaspernj/api-maker/src/router"
import SessionStatusUpdater from "@kaspernj/api-maker/src/session-status-updater"
import withPath from "on-location-changed/src/with-path"

require("shared/devise")

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

window.sessionStatusUpdater = new SessionStatusUpdater()
sessionStatusUpdater.startTimeout()

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "[request]" */ "components/layout/not-found-page"))
const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}`))
const routeDefinitions = require("shared/route-definitions.json")
const RouterWithLocation = withPath(Router)

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <>
      <Notifications />
      <RouterWithLocation
        history={AppHistory}
        notFoundComponent={LayoutNotFoundPage}
        requireComponent={requireComponent}
        routeDefinitions={routeDefinitions}
        routes={Routes}
      />
    </>
  )
})
